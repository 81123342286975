<script setup lang="ts">
const emit = defineEmits<{
  submit: [];
}>();
</script>

<template>
  <form @submit.prevent="emit('submit')">
    <slot />
  </form>
</template>
